<template>
  <div>
    <MainHeader></MainHeader>
    <router-view></router-view>
    <MainFooter></MainFooter>
		<div class="clearfix" id="blockchain-spinner" :class="spinning == true ? 'show-spinner' : 'hide-spinner'">
      <div class="sk-circle" id="text-spinner">
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
      </div>
		</div>
  </div>
</template>

<script>
import debug from 'debug'
const log = debug('app:main');


import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import { storeComputed } from "@/store/store-helper";

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter,
  },
  computed: {
    ...storeComputed,
  },
  watch: {
    spinning() {
    }
  }
}
</script>

<style scoped>
#blockchain-spinner {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(60, 60, 60, 0.5); /* Black background with opacity */
      opacity: 1;
      z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
      cursor: progress; /* Add a pointer on hover */
}
#text-spinner {
      position: relative;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      width: 3rem;
      height: 3rem;
}
.show-spinner {
    display: block;
}
.hide-spinner {
    display: none;
}
</style>

